let paperData = {
  "papers": [
    {
      "title": "Push2AR: Enhancing Mobile List Interactions Using Augmented Reality",
      "venue": "ISMAR 2024: IEEE International Symposium on Mixed and Augmented Reality",
      "authors": "Jonathan Wieland, **Hyunsung Cho**, Sebastian Hubenschimid, Akihiro Kiuchi, Harald Reiterer, and David Lindlbauer",
      "paper_link": null,
      "pdf": null,
      "award": null,
      "award2": null,
      "video": null,
      "website": "https://augmented-perception.org/publications/2024-push2AR.html",
      "acm_dl": null,
      "slides": null,
      "thumbnail": "/img/push2ar_thumbnail.png",
    },
    {
      "title": "Auptimize: Optimal Placement of Spatial Audio Cues for Extended Reality",
      "venue": "UIST 2024: ACM Symposium on User Interface Software and Technology",
      "authors": "**Hyunsung Cho**, Alexander Wang, Divya Kartik, Emily Liying Xie, Yukang Yan, and David Lindlbauer",
      "paper_link": "https://arxiv.org/abs/2408.09320",
      "pdf": "https://arxiv.org/pdf/2408.09320",
      "award": null,
      "award2": null,
      "video": null,
      "website": "https://augmented-perception.org/publications/2024-auptimize.html",
      "acm_dl": "https://dl.acm.org/doi/10.1145/3654777.3676424",
      "slides": null,
      "thumbnail": "/img/auptimize_thumbnail.png",
    },
    {
      "title": "SonoHaptics: An Audio-Haptic Cursor for Gaze-Based Object Selection in XR",
      "venue": "UIST 2024: ACM Symposium on User Interface Software and Technology",
      "authors": "**Hyunsung Cho**, Naveen Sendhilnathan, Michael Nebeling, Tianyi Wang, Purnima Padmanabhan, Jonathan Browder, David Lindlbauer, Tanya R. Jonker, and Kashyap Todi",
      "paper_link": "https://arxiv.org/abs/2409.00784",
      "pdf": "https://arxiv.org/abs/2409.00784",
      "award": null,
      "award2": null,
      "video": null,
      "website": "https://augmented-perception.org/publications/2024-sonohaptics.html",
      "acm_dl": "https://dl.acm.org/doi/10.1145/3654777.3676384",
      "slides": null,
      "thumbnail": "/img/sonohaptics_thumbnail.png",
    },
    {
      "title": "MineXR: Mining Personalized Extended Reality Interfaces",
      "venue": "CHI 2024: ACM Conference on Human Factors in Computing Systems",
      "authors": "**Hyunsung Cho**, Yukang Yan, Kashyap Todi, Mark Parent, Missie Smith, Tanya Jonker, Hrvoje Benko, and David Lindlbauer",
      "paper_link": "https://dl.acm.org/doi/10.1145/3613904.3642394",
      "pdf": "https://dl.acm.org/doi/pdf/10.1145/3613904.3642394",
      "award": null,
      "award2": null,
      "video": "https://youtu.be/7K3eouLCcSw",
      "website": "https://augmented-perception.org/publications/2024-minexr.html",
      "acm_dl": "https://dl.acm.org/doi/10.1145/3613904.3642394",
      "slides": null,
      "thumbnail": "/img/minexr_thumbnail.png",
    },
    {
      "title": "BlendMR: A Computational Method To Create Ambient Mixed Reality Interfaces",
      "venue": "ISS 2023: ACM Interactive Surfaces and Spaces (ISS)",
      "authors": "Violet Han, **Hyunsung Cho**, Kiyosu Maeda, Alexandra Ion, and David Lindlbauer",
      "paper_link": "https://dl.acm.org/doi/abs/10.1145/3626472",
      "pdf": "https://dl.acm.org/doi/pdf/10.1145/3626472",
      "award": "Best Paper Award",
      "award2": null,
      "video": "https://www.youtube.com/watch?v=3vQCdW7UCN8",
      "website": "https://augmented-perception.org/publications/2023-blendmr.html",
      "acm_dl": "https://dl.acm.org/doi/abs/10.1145/3626472",
      "slides": null,
      "thumbnail": "/img/blendmr_thumbnail.png",
    },
    {
      "title": "RealityReplay: Detecting and Replaying Temporal Changes In Situ using Mixed Reality",
      "venue": "IMWUT (UbiComp) 2023: Proceedings of the ACM on Interactive, Mobile, Wearable and Ubiquitous Technologies",
      "authors": "**Hyunsung Cho**, Matthew Komar, and David Lindlbauer",
      "paper_link": "https://dl.acm.org/doi/10.1145/3610888",
      "pdf": "https://dl.acm.org/doi/pdf/10.1145/3610888",
      "award": null,
      "award2": null,
      "video": "https://www.youtube.com/watch?v=wCrh6PpyqUY",
      "website": "https://augmented-perception.org/publications/2023-realityreplay.html",
      "acm_dl": "https://dl.acm.org/doi/10.1145/3610888",
      "slides": null,
      "thumbnail": "/img/realityreplay_thumbnail.png",
    },
    {
      "title": "FinerMe: Examining App-level and Feature-level Interventions to Regulate Mobile Social Media Use",
      "venue": "CSCW 2023: ACM Conference on Computer Supported Cooperative Work",
      "authors": "Adiba Orzikulova, **Hyunsung Cho**, Hye-Young Chung, Hwajung Hong, Uichin Lee, and Sung-Ju Lee",
      "paper_link": "https://dl.acm.org/doi/10.1145/3610065",
      "pdf": "https://dl.acm.org/doi/10.1145/3610065",
      "award": null,
      "award2": null,
      "video": null,
      "website": "https://medium.com/acm-cscw/finerme-examining-app-level-and-feature-level-interventions-to-regulate-mobile-social-media-use-25d97b6dca8",
      "acm_dl": "https://dl.acm.org/doi/10.1145/3610065",
      "slides": null,
      "thumbnail": "/img/finerme_thumbnail.png",
    },
    {
      "title": "A Survey on Remote Assistance and Training in Mixed Reality Environments",
      "venue": "TVCG 2023: IEEE Transactions on Visualization and Computer Graphics",
      "authors": "Catarina G. Fidalgo, Yukang Yan, **Hyunsung Cho**, Mauricio Sousa, David Lindlbauer, and Joaquim Jorge",
      "paper_link": "https://ieeexplore.ieee.org/abstract/document/10049704/",
      "pdf": "https://ieeexplore.ieee.org/stamp/stamp.jsp?arnumber=10049704",
      "award": null,
      "award2": null,
      "video": null,
      "website": "https://augmented-perception.org/publications/2023-training-survey.html",
      "acm_dl": "https://ieeexplore.ieee.org/abstract/document/10049704/",
      "slides": null,
      "thumbnail": "/img/vr_survey_thumbnail.png",
    },
    {
      "title": "FLAME: Federated Learning Across Multi-device Environments",
      "venue": "IMWUT (UbiComp) 2022: Proceedings of the ACM on Interactive, Mobile, Wearable and Ubiquitous Technologies",
      "authors": "**Hyunsung Cho**, Akhil Mathur, and Fahim Kawsar",
      "paper_link": "https://dl.acm.org/doi/10.1145/3550289",
      "pdf": "https://dl.acm.org/doi/pdf/10.1145/3550289",
      "award": null,
      "award2": null,
      "video": null,
      "website": null,
      "acm_dl": null,
      "slides": "https://drive.google.com/file/d/1YUrr84_d31BTcjjWfkm4M4oOCouLaiLo/view?usp=share_link",
      "thumbnail": "/img/flame_thumbnail.png",
    },
    {
      "title": "You Are Not Alone: How Trending Stress Topics Brought #Awareness and #Resonance on Campus",
      "venue": "CSCW 2022: ACM Conference on Computer Supported Cooperative Work",
      "authors": "Ryuhaerang Choi, Chanwoo Yoon, **Hyunsung Cho**, Hwajung Hong, Uichin Lee, and Sung-Ju Lee",
      "paper_link": "https://dl.acm.org/doi/abs/10.1145/3555612",
      "pdf": "https://dl.acm.org/doi/abs/10.1145/3555612",
      "award": null,
      "award2": null,
      "video": null,
      "website": null,
      "acm_dl": null,
      "slides": null,
      "thumbnail": "/img/stresstrendmeter_thumbnail.png",
    },
    {
      "title": "Prediction for Retrospection: Integrating Algorithmic Stress Prediction into Personal Informatics " +
          "\n\nSystems for College Students' Mental Health",
      "venue": "CHI 2022: ACM Conference on Human Factors in Computing Systems",
      "authors": "Taewan Kim, Haesoo Kim, Ha Yeon Lee, Hwarang Goh, Shakhboz Abdigapporov, Mingon Jeong, " +
          "**Hyunsung Cho**, Kyungsik Han, Youngtae Noh, Sung-Ju Lee, and Hwajung Hong",
      "paper_link": "https://dl.acm.org/doi/abs/10.1145/3491102.3517701",
      "pdf": "https://drive.google.com/file/d/15Mbmp5yQL4PuZuLA8on3dYVlt0MAHgzF/view",
      "award": null,
      "award2": null,
      "video": "https://www.youtube.com/watch?v=iATPtKkLFHo",
      "website": null,
      "acm_dl": null,
      "slides": null,
      "thumbnail": "/img/mindscope_thumbnail.png",
    },
    {
      "title": "Reflect, not Regret: Understanding Regretful Smartphone Use with App Feature-Level Analysis",
      "venue": "CSCW 2021: ACM Conference on Computer Supported Cooperative Work",
      "authors": "**Hyunsung Cho**, DaEun Choi, Donghwi Kim, Wan Ju Kang, Eun Kyoung Choe, and Sung-Ju Lee",
      "paper_link": "https://dl.acm.org/doi/10.1145/3479600",
      "pdf": "/pdf/CSCW21_Finesse.pdf",
      "slides": null,
      "award": "Best Paper Award",
      "award2": "Methods Recognition",
      "video": "https://www.youtube.com/watch?v=ZWva_OIv2xw",
      "website": null,
      "acm_dl": "https://dl.acm.org/doi/abs/10.1145/3479600",
      "code": "https://github.com/choch-o/Finesse-public",
      "thumbnail": "/img/finesse_thumbnail.png",
    },
    {
      "title": "I Share, You Care: Private Status Sharing and Sender-Controlled Notifications in " +
          "Mobile Instant Messaging",
      "venue": "CSCW 2020: ACM Conference on Computer Supported Cooperative Work",
      "authors": "**Hyunsung Cho**, Jinyoung Oh, Juho Kim, and Sung-Ju Lee",
      "paper_link": "https://dl.acm.org/doi/abs/10.1145/3392839",
      "pdf": "https://nmsl.kaist.ac.kr/pdf/CSCW20_MyButler.pdf",
      "slides": null,
      "award": null,
      "award2": null,
      "video": "https://www.youtube.com/watch?v=hUmjE5giPC0&feature=youtu.be",
      "website": "https://nmsl.kaist.ac.kr/projects/mybutler/",
      "thumbnail": "/img/mybutler_thumbnail.png",
    },
    {
      "title": "Knocker:  Vibroacoustic-based Object Recognition with Smartphones",
      "venue": "IMWUT (UbiComp) 2019: Proceedings of the ACM on Interactive, Mobile, Wearable and Ubiquitous Technologies",
      "authors": "Taesik Gong, **Hyunsung Cho**, Bowon Lee, and Sung-Ju Lee",
      "paper_link": "https://dl.acm.org/doi/abs/10.1145/3351240",
      "pdf": "https://nmsl.kaist.ac.kr/pdf/IMWUT19_Knocker.pdf",
      "slides": null,
      "award": null,
      "award2": null,
      "video": "https://www.youtube.com/watch?v=SyQn1vr_HeQ&feature=youtu.be",
      "website": "https://nmsl.kaist.ac.kr/projects/knocker/",
      "thumbnail": "/img/knocker_thumbnail.png",
    },
    {
      "title": "Intelligent Positive Computing with Mobile,  Wearable,  and IoT Devices: Literature Review and Research Directions",
      "venue": "Ad Hoc Networks 2019",
      "authors": "Uichin Lee, Kyungsik Han, **Hyunsung Cho**, Kyong-Mee Chung, Hwajung Hong, Sung-Ju Lee, Youngtae Noh,Sooyoung Park,  and John M. Caroll.",
      "paper_link": "https://www.sciencedirect.com/science/article/pii/S157087051830619X",
      "pdf": "https://nmsl.kaist.ac.kr/pdf/AdHoc19_Intelligent.pdf",
      "slides": null,
      "award": null,
      "award2": null,
      "video": null,
      "website": null,
      "thumbnail": "/img/positivecomputing_thumbnail.png",
    },


    // {
    //   "title": "",
    //   "venue": "",
    //   "authors": "",
    //   "paper_link": null,
    //   "slides": null,
    //   "award": null,
    //   "video": null,
    //   "website": null,
    // },
  ],
};

let posterData = {
  "posters": [
    {
      "title": "Facilitating Instant Interactions for Stressful Experiences Sharing and Peer Support",
      "venue": "ACM MobiSys 2022 Demos",
      "authors": "Ryuhaeraeng Choi, Chanwoo Yun, **Hyunsung Cho**, Hwajung Hong, Uichin Lee, and Sung-Ju Lee",
      "paper_link": "https://dl.acm.org/doi/10.1145/3498361.3538672",
      "pdf": null,
      "slides": null,
      "award": null,
      "video": null,
      "website": null
    },
    {
      "title": "Device or User: Rethinking Federated Learning in Personal-Scale Multi-Device Environments",
      "venue": "ACM SenSys 2021 Workshop on Challenges in Artificial Intelligence and Machine Learning for Internet of Things (AIChallengeIoT)",
      "authors": "**Hyunsung Cho**, Akhil Mathur, and Fahim Kawsar",
      "paper_link": "https://dl.acm.org/doi/abs/10.1145/3485730.3493449",
      "pdf": "https://dl.acm.org/doi/pdf/10.1145/3485730.3493449",
      "slides": null,
      "award": null,
      "video": null,
      "website": null
    },
    {
      "title": "I Share, You Care: Private Status Sharing and Sender-Controlled Notifications in " +
          "Mobile Instant Messaging",
      "venue": "ACM CSCW 2020 Demos",
      "authors": "**Hyunsung Cho**, Jinyoung Oh, Juho Kim, and Sung-Ju Lee",
      "pdf": "/pdf/CSCW20Demo_MyButler.pdf",
      "slides": null,
      "award": null,
      "video": null,
      "website": null
    },
    {
      "title": "Sender-Controlled Mobile Instant Message Notifications  Using  Activity Information",
      "venue": "ACM MobiSys 2019 Demos",
      "authors": "**Hyunsung Cho**, Jinyoung Oh, Juho Kim, and Sung-Ju Lee",
      "paper_link": "https://dl.acm.org/doi/10.1145/3307334.3329407",
      "pdf": "https://nmsl.kaist.ac.kr/pdf/MobiSys19_MyButler.pdf",
      "slides": null,
      "award": null,
      "video": null,
      "website": null,
    },
    {
      "title": "Real-Time Object Identification with a Smartphone Knock",
      "venue": "ACM MobiSys 2019 Videos",
      "authors": "Taesik Gong, **Hyunsung Cho**, Bowon Lee, and Sung-Ju Lee",
      "paper_link": "https://dl.acm.org/doi/10.1145/3307334.3328593",
      "pdf": "https://nmsl.kaist.ac.kr/pdf/MobiSys19_Real-Time.pdf",
      "slides": null,
      "award": "Best Video Award",
      "video": "https://www.youtube.com/watch?v=SyQn1vr_HeQ&feature=youtu.be",
      "website": null,
    },
    {
      "title": "Identifying Everyday Objects with a Smartphone Knock",
      "venue": "ACM CHI 2018 Late-Breaking Work",
      "authors": "Taesik Gong, **Hyunsung Cho**, Bowon Lee, and Sung-Ju Lee",
      "paper_link": "https://dl.acm.org/doi/10.1145/3170427.3188514",
      "pdf": "https://nmsl.kaist.ac.kr/pdf/CHIEA18_Identifying.pdf",
      "slides": null,
      "award": null,
      "video": null,
      "website": null,
    },
  ],
};


export { paperData, posterData };